.sidebar_link {
  transition: background-color 0.2s ease;
  text-align: center;
  color: white;
  padding: 0.75rem 0.5rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  svg {
    min-width: 15px;
  }

  .sidebar_link__text_container {
    width: auto;

    .sidebar_link__text {
      margin-left: 1rem;
    }
  }
}

@media screen and (min-width: 896px) {
  .sidebar_link {
    transition: background-color 0.2s ease;
    text-align: left;
    align-items: flex-end;

    &.sidebar_link__slim {
      justify-content: center;
    }

    .sidebar_link__text_container {
      max-height: 20px;
      overflow: hidden;
      width: 100%;
    }

    &:hover {
      background-color: lighten(#00b4ff, 5%);
    }
  }
}
