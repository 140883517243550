.form {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.form_field {
  padding: 1rem 1rem 0;
  width: 100%;

  &.is_half {
    width: 50%;
  }

  &.is_one_third {
    width: 33.3333%;
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
    color: mediumslateblue;
    text-transform: uppercase;
  }

  .input,
  select {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #333;
  }

  .label_input {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
  }
  .input_checkbox {
    margin-right: 0.5rem;
  }
}

.form_submit {
  margin: 0 1rem;
  width: 100%;
  background: mediumslateblue;
  outline: none;
  border: none;
  color: white;
  padding: 1rem;
  cursor: pointer;
  margin-top: 1rem;
}
