.stat_card {
  position: relative;
  flex: 1;
  padding: 0.5rem;
  min-width: 100%;
}

.stat_card__inner {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;

  box-shadow: 0 0 4px 2px rgba(225, 225, 225, 0.6);
  background: rgb(255, 255, 255);
  // background: linear-gradient(to right, white, #00b4ff);
}

.stat_card__info {
  transition: opacity 0.2s ease;
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  background: white;
  color: #3c3c3c;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 0.5rem;
}

.stat_card__info_icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  background: #00b4ff;
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  text-align: center;
  color: white;
  opacity: 0.5;
  z-index: 2;
}

.stat_card__info_icon:hover {
  opacity: 0.7;

  & + .stat_card__info {
    opacity: 1;
  }
}

.stat_card__icon {
  color: #00b4ff;
  font-size: 1.5rem;
  background: #d2eaf4;
  width: 60px;
  height: 60px;
  margin-right: 1rem;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.stat_card__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
}
.stat_card__content {
  flex: 1;
  margin-top: -5px;
}

.stat_card__name {
  font-size: 0.8rem;
  margin-left: 2px;
  font-weight: 300;
  color: #00b4ff;
}
.stat_card__value {
  font-family: "noto-sans", sans-serif;
  font-size: 1.5rem;
  line-height: 1;
}

@media screen and (min-width: 498px) and (max-width: 895px) {
  .stat_card {
    min-width: 50%;
    max-width: 50%;
  }

  .stat_card__value {
    font-size: 1.75rem;
  }
}

@media screen and (min-width: 896px) {
  .stat_card {
    min-width: 250px;
    max-width: 250px;
  }
  .stat_card__name {
    font-size: 1rem;
  }
  .stat_card__value {
    font-size: 2rem;
  }
}

@media screen and (min-width: 896px) {
}
