.search {
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 1.5rem;
}

.search_bar {
  border: none;
  outline: none;
  flex: 1;
  border-radius: 25px;
  padding: 0.4rem 2.2rem 0.4rem 1rem;
  font-size: 0.9rem;
  color: white;
  background: transparent;
  margin-left: auto;
  max-width: 0;
}

.search_bar::placeholder {
  color: rgba(255, 255, 255, 0.755);
}

.search_bar:focus,
.search_bar:focus-within {
  background: lighten(#00b4ff, 5%);
}

.search_icon {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 0.9rem;
  margin-left: -1.6rem;
  margin-bottom: -0.1rem;

  svg {
    fill: rgba(255, 255, 255, 0.775);
  }
}

@media screen and (min-width: 896px) {
  .search {
    max-width: 350px;
  }

  .search_bar {
    padding: 0.6rem 2.5rem 0.6rem 1rem;
    font-size: 0.9rem;
    color: #3c3c3c;
    background: white;
    box-shadow: inset 0 0 8px rgb(220, 220, 220);
    max-width: 100%;
  }

  .search_bar:focus,
  .search_bar:focus-within {
    background: white;
    color: #3c3c3c;
    box-shadow: 0 0 4px 3px rgba(220, 220, 220, 0.5);
  }

  .search_bar::placeholder {
    color: rgba(184, 184, 184, 0.993);
  }

  .search_icon {
    font-size: 1rem;
    cursor: pointer;

    svg {
      fill: #3c3c3c;
    }
  }
}
