.sidebar {
  transition: max-width 0.2s cubic-bezier(0.65, 0, 0.35, 1),
    transform 0.2s cubic-bezier(0.65, 0, 0.35, 1);
  width: 100%;
  background-color: #00b4ff;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  padding: 1rem;
  z-index: 500;

  .toggle_menu {
    display: none;
  }

  &.open {
    transform: translate(0);
  }
}

.sidebar_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebar_top {
  flex: 1;
}

.sidebar_bottom {
  padding: 1rem 0;
}

@media screen and (min-width: 896px) {
  .sidebar {
    max-width: 250px;
    transform: translate(0);
    overflow: hidden;
    position: sticky;

    .toggle_menu {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1rem;
      cursor: pointer;

      svg {
        transition: 0.2s ease;
        fill: white;
      }
    }

    &.slim {
      transition: none;
      max-width: 50px;

      .sidebar_container {
        align-items: center;
      }

      .toggle_menu {
        justify-content: center;

        svg {
          transition: none;
          transform: rotate(180deg);
        }
      }
    }
  }
}
