.hamburger {
  width: 30px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 1rem;
  padding: 0.2rem 0;
  z-index: 999;
  cursor: pointer;

  span {
    transition: opacity 0.2s ease, transform 0.2s ease;
    opacity: 1;
    min-width: 30px;
    height: 2px;
    background: #fff;
    z-index: -1;
  }

  &.open {
    span:first-child {
      transform: translateY(8.5px) rotate(45deg);
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:last-child {
      transform: translateY(-8.5px) rotate(-45deg);
    }
  }
}

@media screen and (min-width: 896px) {
  .hamburger {
    display: none;
  }
}
