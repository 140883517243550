.dropdown_link {
  transition: background-color 0.2s ease;
  display: block;
  text-align: left;
  color: #858484;
  padding: 0.75rem 0.5rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(225, 225, 225, 0.4);

  &:hover {
    color: black;
  }

  &:last-child {
    border: none;
  }
}
