.sidebar_logo {
  margin: -0.15rem 0 1rem 3rem;
  opacity: 0;

  .sidebar_logo__wide {
    display: block;
    width: 180px;

    img {
      width: 100%;
    }
  }

  &.open {
    transition: opacity 0.2s ease;
    transition-delay: 0.2s;
    opacity: 1;
  }
}

@media screen and (min-width: 896px) {
  .sidebar_logo {
    transition: none;
    margin: 0 0 1rem;
    text-align: center;
    opacity: 1;
    width: 100%;

    .sidebar_logo__wide {
      transition: transform 0.2s ease;
      transform: translateX(10px);
      width: 200px;
    }

    .sidebar_logo__slim {
      width: 40px;
    }

    .sidebar_logo__slim,
    .sidebar_logo__wide {
      img {
        object-fit: cover;
        width: 100%;
      }
    }

    .hidden {
      display: none;
    }
  }
}
