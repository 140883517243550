.stats_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  padding: 0 -0.5rem;
  justify-content: center;
}

@media screen and (min-width: 896px) {
  .stats_container {
    justify-content: flex-start;
  }
}
