@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body,
html {
  min-height: 100%;
  height: 100%;
}

body {
  font-family: "Noto Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #3c3c3c;
}

select {
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 4px 3px rgba(220, 220, 220, 0.5);

  &:focus {
    outline: none;
    box-shadow: inset 0 0 8px rgb(220, 220, 220);
  }
}

.container {
  padding: 0 1rem;
}

@media screen and (min-width: 896px) {
  .container {
    padding: 0 1.5rem;
  }
}
