.user_dropdown {
  transition: 0.2s ease;
  opacity: 0;
  pointer-events: none;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 6px 2px rgba(225, 225, 225, 0.6);
  width: 180px;
  padding: 0.75rem;
  position: absolute;
  top: calc(100% + 0.7rem);
  right: 0;
  transform: translateY(10px);
  z-index: 499;

  &.open {
    opacity: 1;
    pointer-events: auto;
    transform: translate(0);
  }
}
