@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap);
body{margin:0;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}
.Logo_sidebar_logo__3y7Ax{margin:-0.15rem 0 1rem 3rem;opacity:0}.Logo_sidebar_logo__3y7Ax .Logo_sidebar_logo__wide__kECtG{display:block;width:180px}.Logo_sidebar_logo__3y7Ax .Logo_sidebar_logo__wide__kECtG img{width:100%}.Logo_sidebar_logo__3y7Ax.Logo_open__3hsfb{transition:opacity .2s ease;transition-delay:.2s;opacity:1}@media screen and (min-width: 896px){.Logo_sidebar_logo__3y7Ax{transition:none;margin:0 0 1rem;text-align:center;opacity:1;width:100%}.Logo_sidebar_logo__3y7Ax .Logo_sidebar_logo__wide__kECtG{transition:transform .2s ease;transform:translateX(10px);width:200px}.Logo_sidebar_logo__3y7Ax .Logo_sidebar_logo__slim__3_L6q{width:40px}.Logo_sidebar_logo__3y7Ax .Logo_sidebar_logo__slim__3_L6q img,.Logo_sidebar_logo__3y7Ax .Logo_sidebar_logo__wide__kECtG img{object-fit:cover;width:100%}.Logo_sidebar_logo__3y7Ax .Logo_hidden__1oGyM{display:none}}
.SidebarLink_sidebar_link__1-ffZ{transition:background-color .2s ease;text-align:center;color:#fff;padding:.75rem .5rem;text-decoration:none;display:flex;align-items:center;border-bottom:1px solid rgba(255,255,255,.2)}.SidebarLink_sidebar_link__1-ffZ svg{min-width:15px}.SidebarLink_sidebar_link__1-ffZ .SidebarLink_sidebar_link__text_container__1k1ar{width:auto}.SidebarLink_sidebar_link__1-ffZ .SidebarLink_sidebar_link__text_container__1k1ar .SidebarLink_sidebar_link__text__BNeAO{margin-left:1rem}@media screen and (min-width: 896px){.SidebarLink_sidebar_link__1-ffZ{transition:background-color .2s ease;text-align:left;align-items:flex-end}.SidebarLink_sidebar_link__1-ffZ.SidebarLink_sidebar_link__slim__3A4o5{justify-content:center}.SidebarLink_sidebar_link__1-ffZ .SidebarLink_sidebar_link__text_container__1k1ar{max-height:20px;overflow:hidden;width:100%}.SidebarLink_sidebar_link__1-ffZ:hover{background-color:#1abcff}}
.Sidebar_sidebar__3qUkX{transition:max-width .2s cubic-bezier(0.65, 0, 0.35, 1),transform .2s cubic-bezier(0.65, 0, 0.35, 1);width:100%;background-color:#00b4ff;height:100%;position:fixed;top:0;left:0;transform:translateX(-100%);padding:1rem;z-index:500}.Sidebar_sidebar__3qUkX .Sidebar_toggle_menu__42JcX{display:none}.Sidebar_sidebar__3qUkX.Sidebar_open__12MMU{transform:translate(0)}.Sidebar_sidebar_container__3O6G3{display:flex;flex-direction:column;height:100%}.Sidebar_sidebar_top__1mTgI{flex:1 1}.Sidebar_sidebar_bottom__3Brhz{padding:1rem 0}@media screen and (min-width: 896px){.Sidebar_sidebar__3qUkX{max-width:250px;transform:translate(0);overflow:hidden;position:-webkit-sticky;position:sticky}.Sidebar_sidebar__3qUkX .Sidebar_toggle_menu__42JcX{display:flex;justify-content:flex-end;margin-bottom:1rem;cursor:pointer}.Sidebar_sidebar__3qUkX .Sidebar_toggle_menu__42JcX svg{transition:.2s ease;fill:#fff}.Sidebar_sidebar__3qUkX.Sidebar_slim__1-KHl{transition:none;max-width:50px}.Sidebar_sidebar__3qUkX.Sidebar_slim__1-KHl .Sidebar_sidebar_container__3O6G3{align-items:center}.Sidebar_sidebar__3qUkX.Sidebar_slim__1-KHl .Sidebar_toggle_menu__42JcX{justify-content:center}.Sidebar_sidebar__3qUkX.Sidebar_slim__1-KHl .Sidebar_toggle_menu__42JcX svg{transition:none;transform:rotate(180deg)}}
*{margin:0;padding:0;box-sizing:border-box}body,html{min-height:100%;height:100%}body{font-family:"Noto Sans",sans-serif}h1,h2,h3,h4,h5,h6{color:#3c3c3c}select{border:none;border-radius:10px;box-shadow:0 0 4px 3px rgba(220,220,220,.5)}select:focus{outline:none;box-shadow:inset 0 0 8px #dcdcdc}.container{padding:0 1rem}@media screen and (min-width: 896px){.container{padding:0 1.5rem}}
.App_wrapper__RCHjb{width:100%;overflow:hidden;height:100vh;min-height:100vh;display:flex}.App_wrapper__RCHjb.App_nav_open__3H-dI{overflow:none}.App_main_content__8NgA7{padding-top:1rem}@media screen and (min-width: 896px){.App_main_content__8NgA7{padding-top:0}}
.Search_search__qU5Br{display:flex;align-items:center;flex:1 1;margin-right:1.5rem}.Search_search_bar__15W8L{border:none;outline:none;flex:1 1;border-radius:25px;padding:.4rem 2.2rem .4rem 1rem;font-size:.9rem;color:#fff;background:transparent;margin-left:auto;max-width:0}.Search_search_bar__15W8L::-webkit-input-placeholder{color:rgba(255,255,255,.755)}.Search_search_bar__15W8L:-ms-input-placeholder{color:rgba(255,255,255,.755)}.Search_search_bar__15W8L::-ms-input-placeholder{color:rgba(255,255,255,.755)}.Search_search_bar__15W8L::placeholder{color:rgba(255,255,255,.755)}.Search_search_bar__15W8L:focus,.Search_search_bar__15W8L:focus-within{background:#1abcff}.Search_search_icon__3MqBH{background:transparent;border:none;box-shadow:none;outline:none;font-size:.9rem;margin-left:-1.6rem;margin-bottom:-0.1rem}.Search_search_icon__3MqBH svg{fill:rgba(255,255,255,.775)}@media screen and (min-width: 896px){.Search_search__qU5Br{max-width:350px}.Search_search_bar__15W8L{padding:.6rem 2.5rem .6rem 1rem;font-size:.9rem;color:#3c3c3c;background:#fff;box-shadow:inset 0 0 8px #dcdcdc;max-width:100%}.Search_search_bar__15W8L:focus,.Search_search_bar__15W8L:focus-within{background:#fff;color:#3c3c3c;box-shadow:0 0 4px 3px rgba(220,220,220,.5)}.Search_search_bar__15W8L::-webkit-input-placeholder{color:rgba(184,184,184,.993)}.Search_search_bar__15W8L:-ms-input-placeholder{color:rgba(184,184,184,.993)}.Search_search_bar__15W8L::-ms-input-placeholder{color:rgba(184,184,184,.993)}.Search_search_bar__15W8L::placeholder{color:rgba(184,184,184,.993)}.Search_search_icon__3MqBH{font-size:1rem;cursor:pointer}.Search_search_icon__3MqBH svg{fill:#3c3c3c}}
.Hamburger_hamburger__2CUTH{width:30px;height:25px;display:flex;flex-direction:column;justify-content:space-between;margin-right:1rem;padding:.2rem 0;z-index:999;cursor:pointer}.Hamburger_hamburger__2CUTH span{transition:opacity .2s ease,transform .2s ease;opacity:1;min-width:30px;height:2px;background:#fff;z-index:-1}.Hamburger_hamburger__2CUTH.Hamburger_open__1YUjn span:first-child{transform:translateY(8.5px) rotate(45deg)}.Hamburger_hamburger__2CUTH.Hamburger_open__1YUjn span:nth-child(2){opacity:0}.Hamburger_hamburger__2CUTH.Hamburger_open__1YUjn span:last-child{transform:translateY(-8.5px) rotate(-45deg)}@media screen and (min-width: 896px){.Hamburger_hamburger__2CUTH{display:none}}
.UserAvatar_user_information__3edYF{display:flex;align-items:center;cursor:pointer}.UserAvatar_user_avatar__j-uFi{width:40px;height:40px;border-radius:50%;overflow:hidden}.UserAvatar_user_avatar__image__ROs11{width:100%;object-fit:cover}.UserAvatar_user_name__1Xpas{display:none}.UserAvatar_user_down_arrow__kZbxJ,.UserAvatar_user_up_arrow__3xcwe{display:none}@media screen and (min-width: 896px){.UserAvatar_user_name__1Xpas{display:inline-block;transition:color .2s ease;color:#5f5f5f;margin-right:1rem}.UserAvatar_user_avatar__j-uFi{margin-right:1rem}.UserAvatar_user_down_arrow__kZbxJ,.UserAvatar_user_up_arrow__3xcwe{display:flex;align-items:center}.UserAvatar_user_down_arrow__kZbxJ svg,.UserAvatar_user_up_arrow__3xcwe svg{transition:fill .2s ease;width:.5rem;fill:#5f5f5f}.UserAvatar_user_down_arrow__kZbxJ svg{margin-top:.25rem}.UserAvatar_user_up_arrow__3xcwe svg{margin-top:.15rem}.UserAvatar_user_information__3edYF:hover .UserAvatar_user_name__1Xpas{color:#000}.UserAvatar_user_information__3edYF:hover .UserAvatar_user_down_arrow__kZbxJ svg,.UserAvatar_user_information__3edYF:hover .UserAvatar_user_down_arrow__kZbxJ svg{fill:#000}}
.UserDropdown_user_dropdown__ewSFL{transition:.2s ease;opacity:0;pointer-events:none;background-color:#fff;border-radius:5px;box-shadow:0 0 6px 2px rgba(225,225,225,.6);width:180px;padding:.75rem;position:absolute;top:calc(100% + 0.7rem);right:0;transform:translateY(10px);z-index:499}.UserDropdown_user_dropdown__ewSFL.UserDropdown_open__8RVJv{opacity:1;pointer-events:auto;transform:translate(0)}
.UserDropdownLink_dropdown_link__1stui{transition:background-color .2s ease;display:block;text-align:left;color:#858484;padding:.75rem .5rem;text-decoration:none;display:flex;align-items:center;border-bottom:1px solid rgba(225,225,225,.4)}.UserDropdownLink_dropdown_link__1stui:hover{color:#000}.UserDropdownLink_dropdown_link__1stui:last-child{border:none}
.User_user_button__2bA8f{position:relative}
.Header_site_header__tXN-L{width:100%;padding:.5rem 0;background:#00b4ff}.Header_site_header__container__3DW8Z{display:flex;align-items:center;justify-content:space-between}.Header_site_header__logo__1mUfh{max-width:40px;margin-right:1rem}.Header_site_header__logo__1mUfh img{width:100%}@media screen and (min-width: 896px){.Header_site_header__tXN-L{background:transparent;padding:1rem 0}.Header_site_header__logo__1mUfh{display:none}}
.Main_main_wrapper__3KERh{flex:1 1;max-width:100%;min-height:100vh;overflow-y:scroll;padding-bottom:1rem}
.PageTitle_page_title__container__3CAeX{margin-bottom:1rem;padding:0 .5rem}.PageTitle_page_title__2z3wP{position:relative;font-weight:300;font-size:1.5rem;display:inline-block;padding-bottom:.5rem}.PageTitle_page_title__2z3wP::before{position:absolute;background:#00b4ff;content:"";left:0;bottom:0;height:2.5px;width:100%}@media screen and (min-width: 896px){.PageTitle_page_title__2z3wP{font-size:2rem}}
.SectionHeading_task_heading__3J_d5{padding:1rem 0;font-weight:400}
.TaskSelect_task_options__FtMR4{margin-top:1rem;padding:0 .25rem}.TaskSelect_task_options__FtMR4 .TaskSelect_task_select__RLYun{padding:1rem;width:100%}@media screen and (min-width: 896px){.TaskSelect_task_options__FtMR4{display:none}}
.TaskTabs_task_tabs__b6O6Q{display:none}@media screen and (min-width: 896px){.TaskTabs_task_tabs__b6O6Q{display:block;margin-top:1rem;position:relative}.TaskTabs_task_tabs__b6O6Q .TaskTabs_task_tabs__active_bar__2hgwX{position:absolute;border-radius:5px;content:"";transition:.4s ease;top:0;left:0;height:42px;z-index:-1;background:#00b4ff;border:1px solid #0896d3}.TaskTabs_task_tabs__b6O6Q .TaskTabs_task_tab__Ccmsv{transition:.5s ease;background:transparent;border:1px solid #0896d33d;padding:.75rem 1.5rem;cursor:pointer;outline:none;margin-right:.5rem;border-radius:5px}.TaskTabs_task_tabs__b6O6Q .TaskTabs_task_tab__Ccmsv:hover{border:1px solid #0896d3}.TaskTabs_task_tabs__b6O6Q .TaskTabs_task_tab__Ccmsv.TaskTabs_task_tab__active__xnvty{background:transparent;color:#fff}.TaskTabs_task_tabs__b6O6Q .TaskTabs_task_tab__Ccmsv .TaskTabs_num_tasks__2rtor{transition:.1s ease;background:#00b4ff;color:#fff;border-radius:50%;display:inline-flex;justify-content:center;align-items:center;min-width:15px;height:15px;font-size:.6rem;margin-left:.25rem;pointer-events:none}.TaskTabs_task_tabs__b6O6Q .TaskTabs_task_tab__Ccmsv.TaskTabs_task_tab__active__xnvty .TaskTabs_num_tasks__2rtor{transition-delay:.2s;background:#fff;color:#00b4ff}}
.TaskItem_task__b85Oy{position:relative;display:flex;align-items:stretch;padding:.5rem .5rem .5rem 1rem;background:#ebf9ff;border:1px solid #b3e9ff;margin-bottom:.25rem}.TaskItem_task__b85Oy .TaskItem_task_name__2anUE{flex:1 1;text-decoration:none;color:#005a80;display:flex;align-items:center}.TaskItem_task__b85Oy .TaskItem_task_name__2anUE:hover{color:#00b4ff}.TaskItem_task__b85Oy.TaskItem_overdue__3EC6_{background:#fff0f0;border:1px solid #ffd6d6}.TaskItem_task__b85Oy.TaskItem_overdue__3EC6_ .TaskItem_task_name__2anUE{flex:1 1;text-decoration:none;color:maroon}.TaskItem_task__b85Oy.TaskItem_overdue__3EC6_ .TaskItem_task_name__2anUE:hover{color:red}.TaskItem_task__b85Oy.TaskItem_today__3TGIx{background:#fffaf0;border:1px solid #ffdfa3}.TaskItem_task__b85Oy.TaskItem_today__3TGIx .TaskItem_task_name__2anUE{flex:1 1;text-decoration:none;color:#805300}.TaskItem_task__b85Oy.TaskItem_today__3TGIx .TaskItem_task_name__2anUE:hover{color:orange}.TaskItem_task__b85Oy .TaskItem_task_days__2sa9w{font-size:.7rem;padding:.5rem 1rem;background:#00b4ff;color:#fff;border-radius:2px;display:flex;align-items:center}.TaskItem_task__b85Oy .TaskItem_task_days__2sa9w.TaskItem_task_days__overdue__1i_Dm{background:red}.TaskItem_task__b85Oy .TaskItem_task_days__2sa9w.TaskItem_task_days__today__3MZ8k{background:orange}.TaskItem_task__b85Oy .TaskItem_task_buttons__1npQX{margin-left:.25rem}.TaskItem_task__b85Oy .TaskItem_task_buttons__1npQX .TaskItem_task_button__kVzLB{font-size:1rem;outline:none;border:none;padding:.5rem 1rem;cursor:pointer;color:#fff;border-radius:2px}.TaskItem_task__b85Oy .TaskItem_task_buttons__1npQX .TaskItem_task_button__kVzLB:first-child{margin:0 .25rem}.TaskItem_task__b85Oy .TaskItem_task_buttons__1npQX .TaskItem_task_button__kVzLB.TaskItem_edit__1hJsn{background:#00d567}.TaskItem_task__b85Oy .TaskItem_task_buttons__1npQX .TaskItem_task_button__kVzLB.TaskItem_delete__OXt-K{background:#f31e1e}

.SingleStat_stat_card__1xXf6{position:relative;flex:1 1;padding:.5rem;min-width:100%}.SingleStat_stat_card__inner__2Vx7A{border-radius:5px;display:flex;align-items:center;justify-content:center;padding:1rem 0;box-shadow:0 0 4px 2px rgba(225,225,225,.6);background:#fff}.SingleStat_stat_card__info__m-cYm{transition:opacity .2s ease;position:absolute;top:1rem;left:1rem;height:calc(100% - 2rem);width:calc(100% - 2rem);display:flex;justify-content:center;align-items:center;opacity:0;background:#fff;color:#3c3c3c;border-radius:5px;font-size:.8rem;padding:.5rem}.SingleStat_stat_card__info_icon__2tStN{position:absolute;top:1rem;right:1rem;font-size:.6rem;display:flex;align-items:center;justify-content:center;padding:.25rem;background:#00b4ff;width:.9rem;height:.9rem;border-radius:50%;text-align:center;color:#fff;opacity:.5;z-index:2}.SingleStat_stat_card__info_icon__2tStN:hover{opacity:.7}.SingleStat_stat_card__info_icon__2tStN:hover+.SingleStat_stat_card__info__m-cYm{opacity:1}.SingleStat_stat_card__icon__kUs8n{color:#00b4ff;font-size:1.5rem;background:#d2eaf4;width:60px;height:60px;margin-right:1rem;border-radius:50%;display:flex;align-items:center;justify-content:center}.SingleStat_stat_card__wrapper__2uQtQ{display:flex;justify-content:center;align-items:center;padding-left:1rem}.SingleStat_stat_card__content__11xlS{flex:1 1;margin-top:-5px}.SingleStat_stat_card__name__1NCj3{font-size:.8rem;margin-left:2px;font-weight:300;color:#00b4ff}.SingleStat_stat_card__value__2KGG7{font-family:"noto-sans",sans-serif;font-size:1.5rem;line-height:1}@media screen and (min-width: 498px)and (max-width: 895px){.SingleStat_stat_card__1xXf6{min-width:50%;max-width:50%}.SingleStat_stat_card__value__2KGG7{font-size:1.75rem}}@media screen and (min-width: 896px){.SingleStat_stat_card__1xXf6{min-width:250px;max-width:250px}.SingleStat_stat_card__name__1NCj3{font-size:1rem}.SingleStat_stat_card__value__2KGG7{font-size:2rem}}
.Stats_stats_container__gPYR_{display:flex;flex-wrap:wrap;align-items:center;max-width:100%;padding:0 -0.5rem;justify-content:center}@media screen and (min-width: 896px){.Stats_stats_container__gPYR_{justify-content:flex-start}}
.AddCustomerForm_form__3B36F{display:flex;flex-wrap:wrap;margin-bottom:3rem}.AddCustomerForm_form_field__22o-U{padding:1rem 1rem 0;width:100%}.AddCustomerForm_form_field__22o-U.AddCustomerForm_is_half__2NJ7S{width:50%}.AddCustomerForm_form_field__22o-U.AddCustomerForm_is_one_third__2ScGW{width:33.3333%}.AddCustomerForm_form_field__22o-U label{display:block;margin-bottom:.5rem;font-size:.8rem;color:#7b68ee;text-transform:uppercase}.AddCustomerForm_form_field__22o-U .AddCustomerForm_input__2gYm1,.AddCustomerForm_form_field__22o-U select{width:100%;padding:.5rem;border-radius:0;box-shadow:none;border:1px solid #333}.AddCustomerForm_form_field__22o-U .AddCustomerForm_label_input__1vpS6{cursor:pointer;display:inline-flex;align-items:center}.AddCustomerForm_form_field__22o-U .AddCustomerForm_input_checkbox__1hxhS{margin-right:.5rem}.AddCustomerForm_form_submit__2ORyq{margin:0 1rem;width:100%;background:#7b68ee;outline:none;border:none;color:#fff;padding:1rem;cursor:pointer;margin-top:1rem}
.AddAccount_customer_list__2HRxz{display:flex;flex-wrap:wrap;align-items:stretch;margin:1rem 0;padding:0 1rem}.AddAccount_customer__3slPD{width:33.333%;display:flex;flex-direction:column;align-items:stretch;justify-content:space-between;border-right:5px solid #7b68ee;margin-bottom:1rem;padding-left:1rem}.AddAccount_customer__3slPD h4{color:#7b68ee;margin-bottom:.5rem;text-transform:capitalize}.AddAccount_customer__3slPD h4.AddAccount_address__10Hnq{font-size:.9rem;font-weight:400}.AddAccount_customer__3slPD section{padding-bottom:1rem}.AddAccount_customer__3slPD section:first-child{flex:1 1;margin-bottom:1rem}.AddAccount_customer__3slPD section:last-child{flex:2 1}
