.wrapper {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  min-height: 100vh;
  display: flex;

  &.nav_open {
    overflow: none;
  }
}

.main_content {
  padding-top: 1rem;
}

@media screen and (min-width: 896px) {
  .main_content {
    padding-top: 0;
  }
}
